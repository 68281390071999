import * as Sentry from '@sentry/browser';

/* global app */

/**
 * Sentry error logging
 * Please make sure that the dsn property is correct
 */
if (app.env !== 'dev' && typeof Sentry !== 'undefined') {
    Sentry.init({
        dsn: 'https://b611a38057234543a87b46a6eca9278b@o919155.ingest.sentry.io/6067090',
        environment: app.env,
        release: app.release
    });

    Sentry.setTag('version', app.version);

    if (app.user) {
        Sentry.configureScope((scope) => {
            scope.setUser(app.user);
        });
    }
}
